import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "default_shift";
const ENDPOINT = "DefaultShifts";
const TITLE = "Turni";
const COLUMNS = [
  { title: 'ID Giorno', field: 'id_dow', type: 'numeric'},
  { title: 'Giorno', field: 'des_dow', type: 'string'},
  { title: 'Codice turno', field: 'cod_shift', type: 'numeric'},
  { title: 'Inizio turno', field: 'tm_start_shift', type: 'time'},
  { title: 'Fine turno', field: 'tm_end_shift', type: 'time'},
  { title: 'Operatori produzione', field: 'num_oerators_production', type: 'numeric'},
  { title: 'Operatori preparazione', field: 'num_oerators_setup', type: 'numeric'},
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}_list/`));
const createData = async (record) => await postApi(`${ENDPOINT}/`, record);
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`${ENDPOINT}/${record.id}`, record);

export default function DefaultShiftsTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}