import axios from "axios";
import {API_URL} from "../constants";
import { getAuthHeader } from "../authentication";
import _ from "lodash";

const getHeaders = () => {
  return {
    ...getAuthHeader(),
    'accept': 'application/json',
    'Content-Type': 'application/json'
  };
}

const obj2urlParams = (o) => {
  return `?${
    _.entries(o)
      .reduce((params, [key, value]) => {
        if (_.isArray(value)) {
          params = params.concat(value.map(value => `${key}=${value}`));
        } else {
          params.push(`${key}=${value}`);
        }
        return params;
      }, [])
      .join("&")
  }`
}

const sanitizeData = (data) => {
  return _.entries(data).reduce((data, [key, value]) => {
    data[key] = ((value) => {
      if (_.isDate(value)) {
        return value.getTime();
      }

      if (_.isObject(value)) {
        return sanitizeData(value);
      }

      return value;
    })(value);

    return data;
  }, {});
}

export const getApi = async (typeObject, args) => {
  const baseUrl = `${API_URL}/${typeObject}`;
  const url = args === undefined ? baseUrl : baseUrl + `${obj2urlParams(args)}`;
  
  const res = await axios.get(url, {headers: getHeaders()})
  return res.data;
}

export const postApi = async (typeObject, data) => {
  const res = await axios.post(`${API_URL}/${typeObject}`, sanitizeData(data), {headers: getHeaders()})
  return res.data;
}

export const updateApi = async (typeObject, data) => {
  const res =  await axios.put(`${API_URL}/${typeObject}`, sanitizeData(data), {headers: getHeaders()})
  return res.data;
}

export const patchApi = async (typeObject, data) => {
  const res =  await axios.patch(`${API_URL}/${typeObject}`, sanitizeData(data), {headers: getHeaders()})
  return res.data;
}

export const deleteApi = async (typeObject) => {
  const res = await axios.delete(`${API_URL}/${typeObject}/`, {headers: getHeaders()})
  return res.data;
}
