import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "machines_stop";
const ENDPOINT = "MachinesStops";
const TITLE = "Fermi Macchina";
const COLUMNS = [
  { title: 'Codice macchina', field: 'cod_machine', type: 'string'},
  { title: 'Inizio fermo', field: 'tms_start_stop', type: 'time'},
  { title: 'Fine fermo', field: 'tms_end_stop', type: 'time'},
  { title: 'Descrizione', field: 'des_stop', type: 'string'},
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}_list/`));
const createData = async (record) => await postApi(`${ENDPOINT}/`, record);
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`${ENDPOINT}/${record.id}`, record);

export default function MachinesStopsTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}