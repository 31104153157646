import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%'
  },
}));