import React from "react";

import { useHistory } from "react-router-dom";

import { Drawer, List, makeStyles } from "@material-ui/core";
import Menu, { ListHeader, ListUser } from "./lists";

import { getUserName, logout } from "../../../utils/authentication";
/* import Footer from "../../Footer"; */

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function logoutHandler(history) {
  logout();

  history.push("/login");
}

export default function Appdrawer({ drawerOpen, toggleDrawer }) {
  const classes = useStyles();
  const history = useHistory();

  const user = getUserName();

  return (
    <Drawer open={drawerOpen} onClose={toggleDrawer}>
      <div className={classes.root}>
        <List component="nav" style={{ minWidth: "250px" }}>
          <ListHeader />
        </List>

        <List component="nav" style={{ minWidth: "250px" }}>
          <ListUser
            userName={user}
            toggleDrawer={toggleDrawer}
            logoutHandler={() => logoutHandler(history)}
          />{" "}
        </List>

        <List component="nav" style={{ minWidth: "250px" }}>
          <Menu onClick={toggleDrawer} />
        </List>
      </div>
      {/* <Footer /> */}
    </Drawer>
  );
}
