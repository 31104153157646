import React from "react";

import { 
  Add,
//   Home,
  PlayCircleFilledRounded,
  StorageRounded
} from '@material-ui/icons';

const menuOptions = [
  /*
  {
    to: "/",
    text: "Home",
    icon: <Home />
  },
  */
  {
    to: "/runs",
    text: "Lista Schedulazioni",
    icon: <PlayCircleFilledRounded />,
    a: "run"
  },
  {
    to: "/runs/new",
    text: "Nuova Schedulazione",
    icon: <Add />,
    I: "create",
    a: "run"
  },
  {
    to: "/data",
    text: "Data Entry",
    icon: <StorageRounded />
  }
];

export default menuOptions;
