import axios from "axios";

import { createContext } from 'react';
import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';

import {API_URL} from "../constants";

export const AUTH_STORAGE_PATH = "auth";
export const AUTH_TIME = 2 * 3600 * 1000; // 2h

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);


const setAuthToken = (token) => {
  localStorage.setItem(AUTH_STORAGE_PATH, token);
}

const deleteAuthToken = () => {
  localStorage.removeItem(AUTH_STORAGE_PATH);
}

const updateAbility = (ability, token) => {
  const rules = token2Rules(token);
  ability.update(rules);
}

const token2Rules = (token) => {
  const scopes = parseJwt(token)?.scopes;
  return scopes?.map(s => {
    const [subject, action] = s.split(':');
    return { subject, action };
  }) || [];
}

const parseJwt = (token) => {
  if (!token) {
    return null;
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const getAuthToken = () => {
  const token = localStorage.getItem(AUTH_STORAGE_PATH);
  const exp = parseJwt(token)?.exp;
  
  if (!exp || exp * 1000 < Date.now()) {
    deleteAuthToken();
    return null;
  }

  return token;
}

export const defaultAbility = (() => {
  const token = getAuthToken();
  return new Ability(token2Rules(token));
})();

export const getUserName = () => {
  const token = localStorage.getItem(AUTH_STORAGE_PATH);
  return parseJwt(token)?.name;
}

export const login = async (username, password, ability) => {
  const data = new FormData();
  data.append('username', username);
  data.append('password', password);

  const res = await axios.post(`${API_URL}/login`, data);
  const token = res.data?.access_token;

  if (!token) {
    throw new Error("Token not found");
  }
  
  setAuthToken(token);
  updateAbility(ability, token);
}

export const logout = () => {
  deleteAuthToken();
}

export const isUserAuthenticated = () => {
  const access_token = getAuthToken();
  return !!access_token;
}

export const getAuthHeader = () => {
  if (isUserAuthenticated())
    return {
      Authorization: `Bearer ${getAuthToken()}`,
    };

  return false;
}