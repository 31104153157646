import React from "react";
import { Link } from "react-router-dom";
import { Can } from "../../../utils/authentication";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";

import menus from "./menu";

import { useLocation } from "react-router-dom";
import custom from "../../../custom";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: 4,
    paddingTop: 4
  },
  listText: {
    color: theme.palette.secondary.dark
  },
  listIcon: {
    color: theme.palette.primary.main
  },
  tabTitle: {
    margin: "0.5vw",
    color: theme.palette.secondary.dark
  },
  listImg: {
    width: "150px",
    marginRight: "2px"
  },
  primary: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export function List({ to, onClick, icon, text }) {
  const classes = useStyles();
  const actualPath = useLocation().pathname;

  const selected = actualPath === to;

  return (
    <ListItem
      button
      component={Link}
      to={to}
      onClick={onClick}
      selected={selected}
      className={classes.listItem}
    >
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
      <ListItemText className={classes.listText} primary={text} />
    </ListItem>
  );
}

export default function Menu({ onClick }) {
  return (
    <>
      {
        menus.map(({ to, icon, text, divider, I, a }, i) => {
          if (divider) {
            return (
              <Divider key={i} style={{marginTop: 10, marginBottom: 10}}/>
            );
          }

          const menuItem = (
            <List key={i} to={to} onClick={onClick} icon={icon} text={text} />
          );

          if (!a) {
            return menuItem;
          }
          return (
            <Can I={I || "read"} a={a} key={i}>
               {menuItem}
            </Can>  
          );
        })
      }
    </>
  );
}

export function ListHeader() {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <div style={{display: "flex", verticalAlign: "middle"}}>
              <img
                src={custom.app_logo}
                className={classes.listImg}
                alt={custom.app_logo_alt}
              />
              {/* <span className={classes.tabTitle}><b>{custom.app_title}</b></span> */}
            </div>
          }
        />
      </ListItem>
    </>
  );
}

export function ListUser({ userName, toggleDrawer, logoutHandler }) {
  const classes = useStyles();
  let firstLetter = "";

  if (!userName) {
    userName = "Utente";
  }

  firstLetter = userName
    .toString()
    .trim()
    .split(" ")
    .reduce((letters, string) => {
      letters += string[0].toString().toUpperCase();

      return letters;
    }, "");

  return (
    <>
      <ListItem component={Link} to={"/profile"} onClick={toggleDrawer} className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.listText}>
          <b>{userName}</b>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={logoutHandler}>
            <ExitToAppRoundedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}
