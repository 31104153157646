import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "working_order";
const ENDPOINT = "WorkingOrders";
const TITLE = "Ordini di lavoro";
const COLUMNS = [
  { title: 'Codice ordine', field: 'cod_working_order', type: 'string'},
  { title: 'Data creazione', field: 'dat_creation_working_order', type: 'date'},
  { title: 'Codice cliente', field: 'cod_customer_working_order', type: 'string'},
  { title: 'Codice prodotto', field: 'cod_item', type: 'string'},
  { title: 'Misura prodotto', field: 'cod_item_measure', type: 'string'},
  { title: 'Quantità', field: 'val_quantity_working_order', type: 'numeric'},
  { title: 'Data spedizione confermata', field: 'dat_confirmed_delivery', type: 'date'},
  { title: 'Priorità', field: 'val_priority', type: 'numeric'},
  { title: 'In produzione', field: 'flg_live', type: 'boolean'}
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}_list/`));
const createData = async (record) => await postApi(`${ENDPOINT}/`, record);
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`${ENDPOINT}/${record.id}`, record);

export default function WorkingOrdersTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}