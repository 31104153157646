import React from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import { Can, isUserAuthenticated } from "../../../utils/authentication";

export default class CanRoute extends Component { 
  render() {
    const {component: Component, I, a, ...props} = this.props;

    return (
      <Route
        {...props}
        render={(props) => {
          if (!isUserAuthenticated()) {
            return (
              <Redirect to="/login" />
            );
          }

          const component = ( <Component {...props} /> );

          return (I && a) ? 
            (
              <Can I={I} a={a}>
                {component}
              </Can>
            ) : component;
        }}
      />
    );
  }
}
