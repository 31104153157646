import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 2),
    width: '100%'
  },
  paper_detail: {
    padding: theme.spacing(2),
    width: '100%'
  },
}));