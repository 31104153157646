import React from "react";
import CanTable from "../CanTable";

const ENTITY = "run";
const COLUMNS = [
  { title: 'Run', field: 'id_run', type: 'numeric'},
  { title: 'Codice Produzione', field: 'cod_production', type: 'string'},
  { title: 'Inizio', field: 'tms_start', type: 'datetime'},
  { title: 'Fine', field: 'tms_end', type: 'datetime'},
  { title: 'Codice Macchina', field: 'cod_machine', type: 'string'},
  { title: 'Tipo', field: 'cod_type_interval', type: 'string'},
  { title: 'Codice Cliente', field: 'cod_customer', type: 'string'},
];

export default function GanttBlocksTable(props) {
  return (
    <CanTable
      title={props.title}
      entity={ENTITY}
      columns={COLUMNS}
      data={props.data}
      actions={[{
        icon: "launch",
        tooltip: "Seleziona",
        onClick: (event, rowData) => props.onSelect(rowData)
      }]}
    />
  );
}