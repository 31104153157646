import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import custom from "../../../custom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    color: "#212121",
    backgroundColor: "#fafafa",
  },
  img: {
    width: "120px",
    height: "auto",
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleContainer: {
    marginTop: "0.5vw",
    display: "flex",
    verticalAlign: "middle",
    color: theme.palette.secondary.dark
  },
  title: {
    marginTop: "1.5vh"
  },
  label: {
    fontSize: '0.7rem'
  }
}));

export default function Appbar({ toggleDrawer }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.titleContainer}>
              <Link to={"/"}>
                <img
                  className={classes.img}
                  src={custom.app_logo}
                  alt={custom.app_logo_alt}
                />
              </Link>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}
