import React from "react";
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import { useAbility } from '@casl/react';
import { AbilityContext, Can } from '../../../utils/authentication';


const exportLocalCsv = (cols, datas, filename, seprator) => {
  const local_datas = datas.map((row) => {
     return row.map(item => {
       if (item === null) 
         return null; 
       return item.toLocaleString('it-IT');
     })
     
   })
  ExportCsv(cols, local_datas, filename, seprator)
}


function CanTable(props) {
  const { I, entity, editable, options, ...rest} = props;
  const ability = useAbility(AbilityContext);

  return (
    <Can I="read" a={entity}>
      <MaterialTable
        editable={{
          ...editable,
          onRowAdd: ability.can('create', entity) ? editable?.onRowAdd : null,
          onRowUpdate: ability.can('update', entity) ? editable?.onRowUpdate : null,
          onRowDelete: ability.can('delete', entity) ? editable?.onRowDelete : null
        }}
        options={{
          ...options,
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, `${props.title} export`)
          }, {
            label: 'Export CSV',
            exportFunc: (cols, datas) => exportLocalCsv(cols, datas, `${props.title} export`, ';')
          }],
          exportAllData: true
        }}
        {...rest}
      />
    </Can>
  );
}

export default CanTable;