import React from "react";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      style={{width: '100%'}}
      {...other}
    >
      {value === index && (<>
        {children}
      </>)}
    </div>
  );
}