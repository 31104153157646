import { createMuiTheme } from '@material-ui/core/styles';

// Color import
import { blueGrey } from '@material-ui/core/colors';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#005a87",
      main: "#00475c",
      dark: "#00394c",
      contrastText: '#ffffff',
    },
    secondary: {
      light: blueGrey[400],
      main: blueGrey[700],
      soft: blueGrey[900],
      contrastText: '#fff',
    },
  },
});

export default theme;